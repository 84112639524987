<div class="p-mdc-dialog-content">
  <p>
    Updates have taken place on the Algo solution.  Please click "OK" below to make sure you have the best Algo experience.
  </p>
</div>
<div class="action-panel text-right btn-wrapper">
  <div>
    <app-button label="Ok" buttonClass="btn btn-primary m-right" color="warn" (buttonClick)="confirm()"> </app-button>
  </div>
</div>
