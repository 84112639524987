import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingBarService } from '@ngx-loading-bar/core';
import moment from 'moment';
import {
  USER_TOKEN_INFO,
  IS_CUSTOM_LOGIN,
  IS_MSAL_LOGIN,
  IS_TURTLE_LOGIN,
  POST_AUTH_TOKEN_INFO,
  USER_ID,
  Selected_Currency,
} from '@algomusinc/domain';
import { AuthService, LocalstorageService, PrimengToasterService, NotificationService } from '@algomusinc/services';
import { environment } from '../environments/environment';
import { OktaAuthService } from '@algomusinc/okta';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private oktaAuthService!: OktaAuthService;
  private loaderRefs = new Map<string, any>();

  constructor(
    private storage: LocalstorageService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private snackbar: PrimengToasterService,
    private loadingBar: LoadingBarService,
    private injector: Injector
  ) {
    if (environment.okta.enableOkta) {
      this.oktaAuthService = this.injector.get(OktaAuthService);
    }
  }

  private readonly methodsType: string[] = ['POST', 'PUT'];
  private readonly hideLoaderList: string[] = [
    `${environment.AppFBSpreading}/SpreadingForecastAddCommitMeasures`,
    `${environment.FileManagementURL}/check-data-module-progress`,
  ];
  private readonly slowLoaderList: string[] = [
    `${environment.AppFBForcast}/fbForecastDefaultAdd`,
    `${environment.AppFBForcast}/fbForecastGetPerformance`,
  ];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.getToken();
    request = this.addAuthHeaders(request, token);

    // Add custom headers
    request = this.addCustomHeaders(request);

    // Modify body for specific methods
    if (this.methodsType.includes(request.method)) {
      request.body.modifiedById = this.storage.get(USER_ID)
    }

    // Loader management
    let ref = this.loaderRefs.get(request.url) || this.loadingBar.useRef('http');
    if (this.hideLoaderList.includes(request.url)) {
      ref.complete();
    } else if(this.slowLoaderList.includes(request.url)) {
      if (!ref) {
        this.loaderRefs.set(request.url, ref);
      } else {
        this.resetLoader(ref);
      }
      this.startSlowProgressLoader(ref);
    }else {
      ref.start();
      this.loaderRefs.set(request.url, ref);
      return next.handle(request).pipe(
        catchError((error) => this.handleError(error)),
        finalize(() => ref.complete())
      );
    }

    return next.handle(request).pipe(
      tap((event: any) => {
        if (event instanceof HttpResponse) {
          this.completeLoader(ref);
        }
      }),
      catchError((error) => {
        this.handleError(error),
        this.completeLoader(ref);
        throw error;
      })
    );
  }
  private resetLoader(ref: any): void {
    if (!ref.slowProgress) {
      ref.slowProgress = {
        interval: null,
        progress: 0,
        maxTime: 4 * 60 * 1000,
        startTime: Date.now(),
      };
    }
    if (ref.slowProgress.interval) {
      clearInterval(ref.slowProgress.interval);
      ref.slowProgress.interval = null;
    }
    ref.slowProgress.progress = 0;
    ref.slowProgress.startTime = Date.now();
  }
  private startSlowProgressLoader(ref: any): void {
    if (!ref.slowProgress) {
      ref.slowProgress = {
        interval: null,
        progress: 0,
        maxTime: 4 * 60 * 1000,
        startTime: Date.now(),
      };
    }

    if (ref.slowProgress.interval) {
      clearInterval(ref.slowProgress.interval);
    }

    ref.start(0);

    ref.slowProgress.interval = setInterval(() => {
      const elapsedTime = Date.now() - ref.slowProgress.startTime;
      const progress = this.calculateSlowProgress(elapsedTime, ref.slowProgress.maxTime);

      if (progress < 95) {
          ref.set(progress);
      } else {
        clearInterval(ref.slowProgress.interval);
      }
    }, 100);
  }

  private calculateSlowProgress(elapsedTime: number, maxTime: number): number {
    const normalizedTime = elapsedTime / maxTime;
    return Math.min(95, 95 * (1 - Math.exp(-normalizedTime * 3)));
  }

  private completeLoader(ref: any): void {
    if (ref.slowProgress?.interval) {
      clearInterval(ref.slowProgress.interval);
      ref.slowProgress.interval = null;
    }
    ref.complete();
  }


  private getToken(): string | null {
    if (this.storage.get(IS_CUSTOM_LOGIN)) {
      return this.storage.get(USER_TOKEN_INFO);
    }
    if (this.storage.get(IS_MSAL_LOGIN)) {
      return this.storage.get(USER_TOKEN_INFO);
    }
    if (this.storage.get(IS_TURTLE_LOGIN)) {
      return this.storage.get(USER_TOKEN_INFO);
    }
    if (this.oktaAuthService) {
      return this.oktaAuthService.oktaAuth.getAccessToken() || '';
    }
    return null;
  }

  private addAuthHeaders(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      const isPostAuth = request.url.startsWith(environment.ClusterBaseUrl);
      const authToken = isPostAuth ? this.storage.get(POST_AUTH_TOKEN_INFO) : token;
      return request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`),
      });
    }
    return request;
  }

  private addCustomHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const excludedUrls = [
      `${environment.AppDocument}/UploadFiles`,
      `${environment.AppFBForcast}/Import`,
      `${environment.AppFBForcast}/ImportVerticalLayout`,
      `${environment.ReplenishmentPOG}/ImportPOG`,
      `${environment.ReplenishmentConfig}/UploadStorePriorityCSV`,
      `${environment.AppItemManager}/UploadItemManagerCSVAsTable`,
      `${environment.AccountHierarchy}/UploadAccountHierarchyCSVAsTable`,
      `${environment.CalendarHierarchy}/UploadCalendarHierarchyCSVAsTable`,
      `${environment.AppCommonCode}/DocumentAdd`,
      `${environment.GenAiapi}/ask`,
      `${environment.GenAiapi}/process_csv`,
      `${environment.GenAiapi}/process_link`,
      `${environment.GenAiapi}/get_col_description`,
      `${environment.AppFBForcast}/ImportNew`,
      `${environment.RunningWeekAccSetting}/Import`
    ];
    if (!excludedUrls.some(url => request.url === url)) {
      // Add 'Content-Type' header if not present
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json'),
        });
      }
      // Add 'Accept' header
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
    }
    request = request.clone({
      headers: request.headers.set('x-application-key', 'AdminPortal'),
    });
    const currency = this.storage.get(Selected_Currency);
    if (currency) {
      request = request.clone({
        headers: request.headers.set('SelectedCurrencyId', currency.toString()),
      });
    }
    const tenant = this.storage.get('ACTIVE_CLIENT') || { name: environment.TENANT_KEY };

    if( request.url.startsWith(`${environment.AppTenant}`)){
      request = request.clone({
        headers: request.headers.set('TenantKey', environment.TENANT_KEY),
      });
    }
    else{
      request = request.clone({
        headers: request.headers.set('TenantKey', tenant.name),
      });
    }

    const useTokenApi = this.storage.get(IS_MSAL_LOGIN) ? 'true' : 'false';
    request = request.clone({
      headers: request.headers.set('UseTokenApi', useTokenApi),
    });
    return request;
  }

  private addModifiedById(request: HttpRequest<any>): HttpRequest<any> {
    const userId = this.storage.get(USER_ID);
    if (userId && request.body) {
      return request.clone({
        body: { ...request.body, modifiedById: userId },
      });
    }
    return request;
  }

  private handleError(error: any): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.handleUnauthorized();
      } else if (error.error?.error?.includes('Multiple planner')) {
        this.snackbar.error( 'A new planner session has been opened.', 'Multiple Planner Operations');
      }else if (error.status === 500) {
        this.snackbar.error('Internal Server Error occurred. Please try again later.','Error');
      }else if (error.status === 0) {
        this.snackbar.error('Error Occur. Please try again later','Error');
      } else {
        this.spinner.hide();
      }
    }
    return throwError(() => error);
  }

  private handleUnauthorized(): void {
    const navigator = this.storage.get('naivigator');
    localStorage.clear();
    this.storage.set('naivigator', navigator);
    window.location.reload();
  }
}
