import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subject, takeUntil, tap } from 'rxjs';
import { SwPush, SwUpdate, VersionEvent } from '@angular/service-worker';
import {
  BotService,
  LoaderService,
  LocalstorageService,
  NotificationService,
} from '@algomusinc/services';
import { environment } from '../environments/environment';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { VersionUpdateComponent } from './version-update/version-update.component';
import { DYNAMIC_DIALOG_CONFIG } from '@algomusinc/domain';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Algo';
  isParentPortal = false;
  isUpdatedVersion = false;
  version: string = '12345';

  @ViewChild('confirmTemp') confirmTemp!: TemplateRef<any>;
  public dialogDefaultConfig: DynamicDialogConfig = JSON.parse(DYNAMIC_DIALOG_CONFIG.STATIC || '{}');

  private userActivityTimeout!: any;
  private userInactive = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private dialog: DialogService,
    private router: Router,
    private storage: LocalstorageService,
    private botService: BotService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.initializeServiceWorker();
  }

  private initializeServiceWorker(): void {
    if (this.swUpdate.isEnabled) {
      this.checkForUpdates();
      interval(2 * 60 * 1000) // Check every 2 minutes
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.swUpdate.checkForUpdate().catch(console.error));
    }
  }

  private checkForUpdates(): void {
    this.swUpdate.versionUpdates
      .pipe(
        tap((event: VersionEvent) => {
          switch (event.type) {
            case 'VERSION_DETECTED':
              console.log('New version detected. Installing...');
              this.swUpdate.activateUpdate().catch(console.error);
              break;
            case 'VERSION_READY':
              console.log('New version ready. Prompting user...');
              this.promptUserForUpdate();
              break;
            case 'VERSION_INSTALLATION_FAILED':
              console.warn('Failed to install the update. Clearing cache...');
              this.reloadApp(true);
              break;
            default:
              console.log('No new version detected.');
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private promptUserForUpdate(): void {
    const ref = this.dialog.open(VersionUpdateComponent, {
      ...this.dialogDefaultConfig,
    });
    ref.onClose.subscribe(() => this.reloadApp());
  }
  private reloadApp(clearCache: boolean = false): void {
    if (clearCache) {
      this.clearCookiesAndStorage();
    }
    window.location.reload();
  }

  private clearCookiesAndStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.userActivityTimeout);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
